<template>
    <div>
      <b-modal
        ref="my-modal"
        centered
        cancelTitle="No"
        cancel-variant="outline-secondary"
        title-tag="div"
      >
        <validation-observer ref="simple">
          <b-card-code>
            <b-form >
              <validation-provider #default="{ errors }" name="reason" rules="required">
                <b-form-group
                  label-for="reason"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> اسباب الرفض</label>
                  <v-select
                    v-model="reason"
                    :options="reasonOption"
                    :reduce="(val) => val.id"
                    label="reason_text"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
  
              <b-col>
              <b-form-group label-for="other">
                <validation-provider #default="{ errors }" name="other" rules="">
                  <label>سبب اخر</label>

                  <b-form-input
                
                    :state="errors.length > 0 ? false : null"
                
                    v-model="otherReason"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            </b-form>
          </b-card-code>
          
 
        </validation-observer>
        <!-- </b-card-code> -->
  
        <template #modal-footer>
          <b-button variant="purple" @click="addRejectReason">
            <span class="align-middle"> حفظ </span>
          </b-button>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  // import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
  import vSelect from "vue-select";
  // import person from './person.vue';
  
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import {
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BModal,
    BFormInvalidFeedback,
    BTable,
  } from "bootstrap-vue";
  import flatPickr from "vue-flatpickr-component";
  // import '@core/scss/vue/libs/vue-flatpicker.scss';
  import "flatpickr/dist/flatpickr.css";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import "cleave.js/dist/addons/cleave-phone.us";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  
  import { required, max, is_earlier, length } from "@validations";
  
  import { BFormDatepicker, BFormInput, BFormTextarea, BButton } from "bootstrap-vue";
  import { personDetails } from "@/@core/mixins/personDetails.js";

  // import {
  //   BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
  // } from 'bootstrap-vue'
  export default {
    name: "family-number",
    mixins: [personDetails],
  
    components: {
      BTable,
      length,
      is_earlier,
      BCardCode,
      BModal,
      required,
      BFormInvalidFeedback,
      BButton,
      max,
      BForm,
      ValidationProvider,
      ValidationObserver,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
      flatPickr,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      ToastificationContent,
      BRow,
  
      BCol,
    },
  
    data() {
      return {
      
  id:'',
      
        reasonOption: [],
        otherReason:'',
        reason: '',
      };
    },
  
    created() {
   //   console.log(" this.getReasonOption()")
      this.getReasonOption();
    },
  
    methods: {
        getReasonOption(){

            this.$http.get(`/api/v1/redirection-reject-reason`).then(res=>{
     //       console.log("rejectresons",res)
            this.reasonOption=res.data.data.data
       
            })

        },
      resetModal() {
        this.reason ='';
      
        requestAnimationFrame(() => {
          this.$refs.simple.reset();
        });
      },
      show(id) {
        this.id=id
        this.$refs["my-modal"].show();
      },
    
      addRejectReason() {
      
        let data={
            rejection_reason_id:this.reason,
            details:this.otherReason
        }
        this.$http.post(`/api/v1/service_referrals/${this.id}/reject`,data).then(res=>{
            //console.log(res)
        this.reason=''
        this.$emit('fetchData')
              this.$swal({
              title: "تم الرفض للحالة ",
        icon: "success",
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.$refs["my-modal"].hide();
        }).catch((error) => {
            
            //console.log(error.response.data.message);
            this.$swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "info",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })})
  
 
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  <style scoped>
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .btn-purple {
    color: white;
    background-color: #0971b8 !important;
    width: 200px;
  }
  .btn-edit {
    color: white;
    width: 200px;
    background-color: #28c76f !important;
  }
  .btn-delete {
    color: white;
    width: 200px;
    background-color: #ea5455 !important;
  }
  
  .but {
    top: 25px;
  }
  </style>
  